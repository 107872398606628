<!--
 * @Author: your name
 * @Date: 2020-11-24 16:18:45
 * @LastEditTime: 2020-12-16 11:46:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\projectList.vue
-->
<template>
    <div class="projectList-main-box">
        <el-divider content-position="left">{{$t('project.pro-management')}}</el-divider>
        <div class="button-box">
            <el-button type="primary" icon='el-icon-plus' class="marginR10" @click="addProject">{{$t('project.new.project')}}</el-button>
            <el-popconfirm
                    :title="$t('project.sure.delete')"
                    @confirm='projectListDeleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('project.delete')}}</el-button>
            </el-popconfirm>
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #proName='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data)'>{{data.proName}}</a>
            </template>
            <template #status='{data}'>
                {{data.status==9?$t('project.completed'):$t('project.undone')}}
            </template>
            <template #cityNameNational='{data}'>
                {{data.cityNameNational ? data.cityNameNational : data.cityNameInternational}}
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        baseTable
    },
    props: {

    },
    data() {
        return {
            loading:false,
            proIds:[],
            dataRows:[],
            column:[{
                title: this.$t('project.name'),
                value: 'proName',
                slot: 'proName'
            },
            {
                title: this.$t('project.city'),
                value: 'cityNameNational',
                slot: 'cityNameNational'
            },
            {
                title: this.$t('project.state'),
                value: 'status',
                slot: 'status'
            },{
                title: this.$t('project.create.time'),
                value: 'createTime',
            }],
            selectDataList:[],
            size:10,
            current:1,
            total:1
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.projectListGetPage();
    },
    methods: {
        async addProject(){
            let res = await this.API.tprojectCreateProject()
            if (res.success) {
                this.$router.push({path:'/addProjectList',query:{id:res.object}})
                this.$store.commit('setInvFormData',{})
                this.$store.commit('setPoFormData',{})
                sessionStorage.setItem('setActive',2)
            }
        },
        /*获取列表数据*/
        async projectListGetPage(){
            this.loading = true
            let res = await this.API.projectListGetPage({
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },


        /*跳转详情页面*/
        pushEditUrl(data){
            this.$router.push({path: '/editProjectList', query: {id:data.proId,status:data.status}});
            localStorage.setItem("statusFlag",data.status)
        },

        /*删除*/
        async projectListDeleteBatch(){
            let flagNum
            if (this.proIds.length == this.dataRows.length) {
                flagNum = true
            }else{
                flagNum = false
            }
            let res = await this.API.projectListDeleteBatch({
                proIds:this.proIds
            })
            if(res.success){
                this.$message({
                    message: this.$t('delete.project.success'),
                    type: 'success'
                });
                if (flagNum) {
                    this.current = 1
                }
                this.projectListGetPage()
                this.proIds.forEach((item)=>{
                    let proIDAndINv = localStorage.getItem(`p${item}`).split(',')
                    console.log(proIDAndINv,item,"proIDAndINv");
                    (proIDAndINv||[]).forEach((invID)=>{
                        localStorage.removeItem(`p${item}+${invID}`)
                    })
                    localStorage.removeItem(`p${item}`)
                })
            }else{
                this.$message({
                    message: this.$t('delete.project.fail'),
                    type: 'warning'
                });
            }
        },

        handleSelectionChange(val){
            this.proIds = val.map( item=>{
                return item.proId
            })
        },
        handleSizeChange(val) {
          this.size = val
          this.current = 1
            this.projectListGetPage()
        },

        handleCurrentChange(val) {
            this.current = val
            this.projectListGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>
.projectList-main-box{
    
}
</style>

